<template>
  <KioskForm v-if="form" v-model="form">
    <template #submitted="submittedProps">
      <slot name="submitted" v-bind="submittedProps" />
    </template>
    <template #default>
      <VContainer class="ml-5 error-container">
        <VRow>
          <VCol cols="10" mb-4 class="error-wrapper">
            <KioskDialFormNotifications :form="form" />
          </VCol>
          <VCol cols="10">
            <KioskFormField
              v-model="form.fields.pin"
              solo
              autocomplete="off"
              autocorrect="off"
              autofill="off"
            />
            <VBtn
              type="submit"
              color="primary"
              block
              large
              :loading="form.state.loading"
              :disabled="form.$v.$invalid"
              data-heap-id="field_pin"
              px-4
            >
              <span v-text="form.$t('login.btn.text')" />
            </VBtn>
          </VCol>
        </VRow>
      </VContainer>
    </template>
  </KioskForm>
</template>

<script>
import { required } from '@/validators'
import { FormBuilder } from '@/lib/kiosk'
import KioskForm from '@/components/KioskForm'
import KioskFormField from '@/components/KioskFormField'
import KioskDialFormNotifications from './KioskDialFormNotifications'

export default {
  name: 'KioskDialPinForm',
  components: {
    KioskForm,
    KioskFormField,
    KioskDialFormNotifications
  },
  props: {
    formData: {
      type: Function,
      default() {
        return {
          pin: null
        }
      }
    },
    noError: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: null,
      fields: this.formData()
    }
  },
  validations() {
    return {
      fields: {
        pin: {
          required
        }
      }
    }
  },
  computed: {
    showErrorSlot() {
      return (error) => {
        if (this.noError) {
          return false
        }

        return ![undefined, null, false].includes(error)
      }
    }
  },
  mounted() {
    this.form = FormBuilder.fromComponent(this)
      .setFields((as) => ({
        pin: as.component('VTextField')
      }))
      .setSubmitHandler((formState) => this.verify(formState))
      .build('dial')
  },
  methods: {
    async verify(payload) {
      await this.$store.dispatch('kiosk/verifyEmployeeCustomId', {
        customId: payload.pin
      })
    }
  }
}
</script>
