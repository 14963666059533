<template>
  <VRow class="fill-height">
    <VCol cols="12" align-self="stretch">
      <KioskDialLoginCard class="cardPrimary fill-height">
        <KioskDialPinForm @submitted="onDialLoginFormSubmitted" />
      </KioskDialLoginCard>
    </VCol>
  </VRow>
</template>

<script>
import KioskDialPinForm from './components/KioskDialPinForm'
import KioskDialLoginCard from './components/KioskDialLoginCard'

export default {
  name: 'DialPin',
  components: {
    KioskDialPinForm,
    KioskDialLoginCard
  },
  methods: {
    onDialLoginFormSubmitted(submitOk) {
      if (submitOk) {
        this.$router.push({ name: 'shift-control.index' })
      }
    }
  }
}
</script>
